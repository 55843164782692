<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>SMS</h2>
        <p>You can see all the SMS settings here.</p>
      </div>
      <div class="api-setting">
        <h3>Taqnyat SMS Settings</h3>
        <div class="notification">
          <div class="first-container">
            <!-- <div class="api-inputs">
              <label>Account SID</label>
              <input type="text" placeholder="Lorem Ipsum" />
            </div> -->
            <div class="api-inputs">
              <label>Auth Token</label>
              <input type="text" placeholder="Lorem Ipsum" v-model="settings.token" />
            </div>
            <div class="api-inputs">
              <label>Sender Name</label>
              <input type="text" placeholder="Lorem Ipsum" v-model="settings.senderName" />
            </div>
            <!-- <div class="api-inputs">
              <label>Number</label>
              <input type="text" placeholder="Lorem Ipsum" />
            </div> -->
          </div>
          <!-- <h3 class="config">Test SMS Config</h3>
          <div class="second-container">
            <div class="api-inputs">
              <label>Number</label>
              <input type="text" placeholder="Lorem Ipsum" />
            </div>
            <div class="api-inputs">
              <label>Test Message</label>
              <input type="text" placeholder="Lorem Ipsum" />
            </div>
          </div> -->
        </div>
        <div class="add-btn">
          <button @click="handleSubmit">
            {{ isLoading ? "Loading.." : "Save Changes" }}
          </button>
        </div>
      </div>
    </section>
  </default-layout>
</template>

<script>
// import CityModel from "@/components/Models/CityModel.vue";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  name: "SmsView",
  components: {
    DefaultLayout
    // CityModel
  },
  data() {
    return {
      cityModel: false,
      isLoading: false,
      settings: {},
    };
  },

  methods: {
    CityModelShow() {
      this.cityModel = !this.cityModel;
    },

    getData() {
      let url = "admin/get-sms-setting";
      this.$axios.get(url).then((res) => {
        this.settings = res.data ? res.data : {};
      });
    },
    async handleSubmit() {
      try {
        this.isLoading = true;
        let url = "admin/sms-setting";
        this.$axios.post(url, this.settings).then(() => {
          this.isLoading = false;
          this.$swal({
            title: "Updated!",
            text: "SMS settings updated successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#FEBB12",
            confirmButtonText: "Done",
            reverseButtons: true,
          });
        });
      }
      catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    faqs: {
      immediate: true,
      handler() {
        console.log(this.settings);
      },
    },
  },
}
</script>

<style scoped>
.top-heading {
  line-height: 1.8;
}

.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}

.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}

.api-setting {
  background: #fff;
  padding: 25px;
  margin-top: 15px;
  border-radius: 5px;
}

.api-setting h3 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}

.notification .first-container {
  display: flex;
  gap:3rem
  /* justify-content: space-between; */
}

.notification .second-container {
  display: flex;
  justify-content: flex-start;
}

.notification .second-container .api-inputs {
  margin-right: 68px;
}

.config {
  margin-top: 20px;
}

.api-inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 28%;
}

.api-inputs label {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  font-size: 14px;
  padding: 20px 0;
}

.api-inputs input {
  outline: none;
  font-size: 12px;
  padding: 15px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  margin: 0 0 8px 0;
  width: 85%;
}

.add-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 100px 0 10px 0;
}

.add-btn button {
  border: none;
  outline: none;
  border-radius: 32px;
  background: #febb12;
  color: #fff;
  padding: 12px 30px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
}
</style>